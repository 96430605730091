<template>
  <span class="project-badge promo-counter"
        :class="counterStyle"
        @click.prevent="filterByStatus">{{status.capitalize()}}
    <template v-if="promosAmount >=0">
      <span class="badge badge-pill badge-default">{{promosAmount}}</span>
    </template>
  </span>
</template>

<script>
import '../../optionsFormatter'

export default {
  props: ['status', 'promosAmount', 'selectedStatus', 'statusFilter'],

  data() {
    return {
      selected: false,
      classesHash: {
        support: 'bg-warning',
        active: 'bg-success',
        claim: 'bg-info',
        presale: 'filter-presale',
        external: 'filter-external',
        successful: 'bg-success',
        unsuccessful: 'bg-danger',
      }
    };
  },

  computed: {
    counterStyle() {
      return (this.classesHash[this.status] || 'bg-grey') + (this.selectedStatus === this.status ? ' selected' : '');
    }
  },

  methods: {
    filterByStatus() {
      this.$emit('statusFilter', this.status);
    }
  }
}
</script>
