<template>
  <tr :class="rowClass">
    <td v-if="draggableTableItems">
      <i class="fa fa-unsorted"></i>
    </td>
    <td v-for="item in rowInfoWithoutSpecFields" :key="item">
      <div v-if="item.key === 'type'">
        <div :style="rowStyle(item.data)" :class="rowData.primary ? 'font-weight-bold': ''">
          {{item.data}}
          <template v-if="rowData.primary">
            <span class="d-block">(Primary)</span>
          </template>
        </div>
      </div>
      <template v-else-if="item.key === 'amount'">
        <div class="d-flex flex-row justify-content-center">
          <ul class="items-list">
            <template v-if="Array.isArray(item.data)">
              <li v-for="dataItem in item.data" :key="dataItem">{{dataItem}}</li>
            </template>
            <template v-else>
              <li>{{item.data}}</li>
            </template>
          </ul>
          <template v-if="rowData.warnings.length">
            <i
              class="fa fa-warning d-inline-block mt-1 ml-1 text-warning"
              data-toggle="tooltip"
              data-placeholder="top"
              :data-html="false"
              data-template='<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-left pre-line"></div></div>'
              :data-title="rowWarningText"
              @click="$emit('dismissWarning', rowData.id)"
            />
          </template>
        </div>
      </template>
      <div v-else-if="item.key==='sponsoringProductData' || item.key === 'bonusProductData'" class="text-left">
        <span :style="rowStyle(item.data)" class="mainInfo"><a :href="item.data.link" target="_blank">{{item.data.mainInfo || item.data.upc}}</a></span>
        <div class="description">{{item.data.description}}</div>
        <template v-if="presale && !rowData.approved && (item.key === 'bonusProductData')">
          <span class="text-warning small">Not Approved</span>
        </template>
      </div>
      <div v-else-if="item.key === 'street_date'">
        <template v-if="street_date === 'Invalid date'">
          <span class="text-warning small" >Not set</span>
        </template>
        <template v-else>
          <div>{{street_date}}</div>
          <span v-if="item.data.overriden" class="text-warning small" >Overridden</span>
        </template>
      </div>
    </td>
    <action-buttons
      :id="rowData.id"
      @edit="$emit('edit', rowData.id)"
      @remove="$emit('remove', rowData.id)"
      :allowDeleteAction="allowDeleteAction"
    ></action-buttons>
  </tr>
</template>

<script>
import actionButtons from "./actionButtons";
import { get } from "lodash";

export default {
  name: "tableRow",
  props: ["rowData", "draggableTableItems", "allowDeleteAction"],
  components: {
    "action-buttons": actionButtons
  },

  computed: {
    presale() {
      return this.rowData && this.rowData.presale
    },
    street_date() {
      return this.$moment.tz(this.rowData.street_date.date, this.rowData.street_date.street_timezone).format('lll');
    },
    rowInfoWithoutSpecFields() {
      let ignoredKeys = ['id', 'primary', 'presale', 'ruleRaw', 'approved', 'external', 'warnings'];
      if (!this.presale) {
        ignoredKeys = [...ignoredKeys, 'street_date']
      }

      const keys = Object.keys(this.rowData).filter(
        rowKey => !ignoredKeys.includes(rowKey)
      );

      return keys.map(key => ({data: this.rowData[key], key}));
    },
    rowClass() {
      return get(this.rowData, 'warnings', []).length ? 'table-warning' : '';
    },
    rowWarningText() {
      if (this.rowData && this.rowData.warnings) {
        const suffix = 'Click to Acknowledge'
        let res = '';
        res = this.rowData.warnings.map((item) => `${(this.rowData.warnings.length > 1) ? '• ' : ''}${item}`).join('\n');
        return `${res}\n\n${suffix}`;
      }

      return '';
    }
  },
  methods: {
    rowStyle(item) {
      return (item.deleted || item === '< Invalid >') ? 'color: red;' : '';
    }
  }
};
</script>

<style scoped lang="scss">
.thead {
  background: #ebebeb;
}

.table .thead th {
  vertical-align: middle;
  border-bottom: none;
}

.fa-unsorted {
  font-size: 16px;
}

span.mainInfo {
  display: block;
  width: 100%;
}

.description {
  color: rgb(175, 174, 174);
  font-size: 10px;
}

ul.items-list li {
  list-style-type: none;
  padding-left: 0;
  white-space: nowrap;
}
</style>
