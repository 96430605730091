<template>
  <div class="data-table">
    <h2 v-if="title">{{ title }}</h2>

    <div v-if="multipleSerialsRowData.length" class="d-none">
      <template v-for="row in multipleSerialsRowData">
        <div :id="`multiple-serials__${row.id}`" :key="`serials_${row.id}`">
          <multiple-serials
              class="px-2"
              :serials="row.serial_number"
              :values="row.card_amount"
              :clickable="true"
          />
        </div>
        <div :id="`multiple-denoms__${row.id}`" :key="`denom_${row.id}`">
          <multiple-serials
              class="px-2"
              :serials="row.serial_number"
              :values="row.card_amount"
          />
        </div>
      </template>
    </div>

    <notification
        v-if="message"
        :successText="!hasError ? message : ''"
        :failureText="hasError ? message : ''"
    >
    </notification>

    <div class="d-flex mb-3 justify-content-start align-items-center">
      <div class="d-flex justify-content-start align-items-center">
        <status-counter v-for="(value, key) in statuses"
                        :status="key"
                        :key="key"
                        :selectedStatus="gridCurrentState.status_type"
                        @statusFilter="onFilterStatus"
                        :promosAmount="value"/>
      </div>
      <template v-if="gridCurrentState.status_type === 'unsuccessful'">
        <error-type-selector
            class="ml-3"
            :options="errorOptions"
            v-model="gridCurrentState.error_type"/>
      </template>
    </div>

    <div class="row">
      <div class="col col-12">
        <ul class="data-table-columns">
          <li v-for="item in columnsList" :key="item">
            <input
                :value="item"
                :id="item"
                type="checkbox"
                v-model="visibleColumns"
                :disabled="(disabledColumns.indexOf(item) !== -1) || isLoading"
                @change="applyColumnDefinition()"
            />
            <label :for="item">{{ columnsListNames[item] }}</label>
          </li>
        </ul>
      </div>
    </div>

    <div class="data-table-rel">
      <ag-grid-vue
          style="width: 100%; height: 76vh"
          class="ag-theme-balham ag-theme-dynamic"
          @grid-ready="onGridReady"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :animateRows="true"
          :suppressDragLeaveHidesColumns="true"
          :enableCellTextSelection="true"
          :rowClassRules="rowClassRules"
          :rowModelType="rowModelType"
          :paginationPageSize="paginationPageSize"
          :cacheOverflowSize="cacheOverflowSize"
          :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
          :infiniteInitialRowCount="infiniteInitialRowCount"
          :maxBlocksInCache="maxBlocksInCache"
      />

      <div v-if="!total" class="data-table-notfound">
        <span v-if="isLoading">Loading...</span>
        <span v-if="!isLoading">No items found. Please change filter settings</span>
      </div>
    </div>
  </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import axios from "axios";
import qs from "qs";
import {isEqual, isEmpty, uniqBy} from 'lodash';
import notification from "../notification";
import {filterByDefault, filterByEqualSelect} from "../../utils/dataTable";
import MultipleSerials from "./MultipleSerials";
import StatusCounter from '../admin/dashboard/statusCounter';
import ErrorTypeSelector from "./ErrorTypeSelector.vue";

export default {
  name: "DataTableRedemptions",
  props: {
    title: {type: String, required: false},
    url: {type: String, required: true},
    projectId: {type: [String], required: true}
  },
  data() {
    return {
      hasError: false,
      message: null,
      isLoading: true,
      gridOptions: {},
      rowClassRules: null,
      columnDefs: null,
      multipleSerialsRowData: [],
      rowModelType: null,
      paginationPageSize: null,
      cacheOverflowSize: null,
      maxConcurrentDatasourceRequests: null,
      infiniteInitialRowCount: null,
      maxBlocksInCache: null,
      total: null,
      gridCurrentState: {
        status_type: null,
        error_type: null,
      },
      statuses: {successful: -1, unsuccessful: -1},
      errorOptions: [],
      loader: `<img src="/loading2.gif" alt=""/>`,
      columnsList: [
        "email",
        "serial_number",
        "promotion_slug",
        "card_amount",
        "bonus_amount",
        "time",
        "actions",
        "external_platform"
      ],
      columnsListNames: {
        email: "Email",
        serial_number: "Serial Number",
        promotion_slug: "Promotion",
        card_amount: "Card Denom",
        bonus_amount: "Bonus Denom",
        time: "Time",
        actions: "Actions",
        external_platform: "External Platform"
      },
      visibleColumns: [
        "email",
        "serial_number",
        "promotion_slug",
        "card_amount",
        "bonus_amount",
        "time",
        "actions"
      ],
      disabledColumns: ["email", "promotion_slug", "actions"]
    };
  },

  components: {
    AgGridVue,
    notification,
    MultipleSerials,
    StatusCounter,
    ErrorTypeSelector,
  },

  beforeMount() {
    this.gridCurrentState = {...this.gridState};

    this.gridOptions = {
      ...this.gridOptions,
      resizable: true,
      rowHeight: 36,
    };

    this.rowModelType = "infinite";
    this.paginationPageSize = 100;
    this.cacheOverflowSize = 2;
    this.maxConcurrentDatasourceRequests = 4;
    this.infiniteInitialRowCount = 1;
    this.maxBlocksInCache = 20;

    this.rowClassRules = {
      "dtr-preview": (param) => param.data?.preview,
      "dtr-fake": (param) => param.data?.fake,
      "dtr-multiple-serials": (params) => params.data?.serial_number?.includes(';'),
    };

    this.applySavedState(['visibleColumns']);
  },

  mounted() {
    this.gridApi = this.gridOptions.api;
    this.subscribeEvents();
  },

  watch: {
    'gridCurrentState.error_type': function (value, oldValue) {
      if (value != oldValue) {
        this.popSavedState({error_type: value});
        this.updateData([]);
      }
    },
  },

  methods: {
    onFilterStatus(status) {
      let newStatus = null;
      if (status && (this.gridCurrentState.status_type !== status)) {
        newStatus = status;
      }

      this.popSavedState({status_type: newStatus});
      this.updateData([]);
    },

    subscribeEvents() {
      this.gridOptions.onGridReady = (e) => {
        this.columnApi = e.columnApi;
        this.gridApi = e.api;
      }

      this.gridOptions.onFilterChanged = (grid) => {
        const filter = grid.api.getFilterModel();
        if (!isEqual(filter, this.gridCurrentState.filter)) {
          this.popSavedState({
            filter,
          });
        }
      };

      this.gridOptions.onSortChanged = (grid) => {
        const sortModel = grid.columnApi.getColumnState()
            .filter(item => !!item.sort)
            .map(item => ({colId: item.colId, sort: item.sort, sortIndex: item.sortIndex}));


        if (!isEqual(sortModel, this.gridCurrentState.sortModel)) {
          this.popSavedState({
            sort: sortModel
          });
        }
      };

      this.gridOptions.onFirstDataRendered = () => {
        this.applySavedState(['status_type', 'error_type'], true);
      };

      this.gridOptions.onCellClicked = (cell) => {
        const $parent = cell.event.target.closest('span');
        const {colId} = cell.column;

        switch (colId.replace(/_?\d+$/, '')) {
          case "email":
          case "serial_number":
          case "promotion_slug":
            if ($parent && $parent.classList.contains('cell-filter-clickable')) {
              this.applyFilters(colId, $parent.textContent);
            }
            break;

          default:
            break;
        }
      };

      this.gridOptions.onPaginationChanged = () => {
        this.applyTooltips();
      };

      this.gridOptions.onViewportChanged = () => {
        this.applyTooltips();
      };

      window.addEventListener("resize", () => {
        let w = window.innerWidth;
        setTimeout(() => {
          if (window.innerWidth === w) {
            this.sizeToFit();
          }
        }, 300);
      });
    },

    applySavedState(props, initial) {
      props.forEach(prop => {
        const values = this[initial ? 'gridState' : 'gridCurrentState'][prop];
        if (['status_type', 'error_type'].includes(prop)) {
          this.gridCurrentState[prop] = values || null;
        }

        if (values && Object.keys(values).length) {
          switch (prop) {
            case 'filter':
              // emulate $nextTick
              setTimeout(() => {
                this.gridApi.setFilterModel(values);
                this.gridApi.onFilterChanged();
              }, 1);
              break;

            case 'sort':
              this.columnApi.applyColumnState({state: this.gridCurrentState[prop]});
              this.gridApi.onSortChanged();
              break;

            case 'visibleColumns':
              this.visibleColumns = values;
              break;
          }
        }
      });
    },

    popSavedState(state) {
      this.gridCurrentState = {
        ...this.gridCurrentState,
        ...state
      };
      location.hash = qs.stringify(this.gridCurrentState);
    },

    applyFilters(filterBy, text) {
      if (filterBy && text) {
        let filterInstance = this.gridApi.getFilterInstance(filterBy);

        filterInstance.setModel({
          type: "contains",
          filter: text
        });

        this.gridApi.onFilterChanged();
      }
    },

    applyTooltips() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    applyPlaceholders() {
      $('input[ref="eFloatingFilterText"], input[ref="eInput"]').each(function () {
        $(this).attr("placeholder", "Filter...");
      });
    },

    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },

    applyColumnDefinition() {
      this.columnDefs = this.columns;
      this.popSavedState({visibleColumns: this.visibleColumns});

      setTimeout(() => {
        this.applyTooltips();
        this.applyPlaceholders();
        this.sizeToFit();
      }, 1);
    },

    updateTimeZoneName(timeZone) {
      if (timeZone) {
        this.columnsListNames = {
          ...this.columnsListNames,
          time: `Time (${timeZone})`
        };
      }
    },

    normalizeRequestedParams(params) {
      let options = {};

      if (params && params.startRow) {
        options.page = params.startRow / this.paginationPageSize + 1
      }

      const filterModel = (params && params.filterModel && !isEmpty(params.filterModel)) ? params.filterModel : (this.gridCurrentState.filter || {});
      const sortModel = params && params.sortModel && params.sortModel || this.gridCurrentState.sortModel || [];

      for (let key in filterModel) {
        switch (filterModel[key].filterType) {
          case "text":
            const filterText =
                filterModel[key].filter ||
                filterModel[key].type;
            options[key] = filterText;
            break;
          case "date":
            const dateFrom = filterModel.time.dateFrom;
            const dateTo = filterModel.time.dateTo;

            switch (filterModel.time.type) {
              case 'equals':
                options.date_from = dateFrom;
                options.date_to = dateFrom;
                break;
              case 'inRange':
                if (new Date(dateFrom) < new Date(dateTo)) {
                  options.date_from = dateFrom;
                  options.date_to = dateTo;
                } else {
                  options.date_from = dateTo;
                  options.date_to = dateFrom;
                }

                break;
              case 'greaterThan':
                options.date_from = dateFrom;
                break;
              case 'lessThan':
                options.date_to = dateFrom;
                break;
            }
            break;

          default:
            options[key] = filterModel[key].value;
            break;
        }
      }

      if (sortModel && sortModel[0]) {
        options = {
          ...options,
          sort_by: sortModel[0].colId,
          sort_direction: sortModel[0].sort,
        }
      }

      const {status_type, error_type} = this.gridCurrentState;
      if (status_type) {
        options.status_type = status_type;

        if (status_type === 'unsuccessful') {
          options.error_type = error_type;
        }
      }

      return options;
    },

    updateData(data) {
      const dataSource = {
        getRows: (params) => {
          this.isLoading = true;
          axios({
            method: "get",
            url: this.requestUrl,
            params: this.normalizeRequestedParams(params)
          }).then(
              response => {
                const {list, errorClasses} = response.data;
                this.errorOptions = errorClasses.sort();

                const rowThisPage = [...list];
                let lastRow = -1;

                if (list.length === this.paginationPageSize) {
                  lastRow = params.endRow + this.paginationPageSize;
                  this.total = params.endRow;
                } else if (list.length < this.paginationPageSize) {
                  lastRow = params.startRow + list.length;
                  this.total = lastRow;
                }

                this.multipleSerialsRowData = uniqBy([
                  ...this.multipleSerialsRowData,
                  ...rowThisPage.filter(item => item.serial_number?.includes(';')),
                ], 'id');

                params.successCallback(rowThisPage, lastRow);

                setTimeout(() => {
                  this.applyTooltips();
                }, 1);
              },
              error => {
                this.onError(error);
              }
          ).finally(() => {
            this.isLoading = false;
          });
        }
      };

      this.gridApi.setDatasource(dataSource);
    },

    onGridReady() {
      this.isLoading = true;
      // todo: make separate request to receive count of items and filters ONLY

      axios({
        method: "get",
        url: this.requestUrl,
        params: this.normalizeRequestedParams()
      }).then(response => {
          this.applySavedState(['filter', 'sort']);
          this.total = response.data.list.length;

          let fh = {};

          response.data.filters.forEach(item => {
            switch (item.type) {
              case "string":
                fh[item.key] = [];
                break;
              case "select":
                fh[item.key] = [...item.value].sort();
                break;
              default:
                break;
            }
          });
          this.fh = fh;

          this.updateTimeZoneName(response.data.timeZone);
          this.applyColumnDefinition();
          this.updateData(response.data.list);
        },
        (error) => {
          this.onError(error);
        }
      ).finally(() => {
        this.isLoading = false;
      });
    },

    emailCellRenderer(params) {
      if (params.value !== undefined) {
        const isFake = params.data.fake || params.data.fake_api;
        const isPreview = params.data.preview;
        let res = `<span class="ico-fake-placeholder">${
            isFake ? '<i class="fa fa-user-secret color-grey"></i>' : ""
        }</span>`;

        res += `${isPreview ? "***" : ""}<span class="cell-filter-clickable">${
            params.data.email
        }</span>`;

        return res;
      } else {
        return '';
      }
    },

    actionCellRenderer(params) {
      const id = params.data && params.data.id;
      if (id) {
        let errorMessage = params.data.error_message && params.data.error_message
            .replace(/{{/g, '{')
            .replace(/}}/g, '}')
            .replace(/\"/g, "'");

        let availableActions = [
          {
            name: "ERROR",
            template: params.data.incomplete ? `<i data-toggle="tooltip" data-placement="top" class="fa fa-adjust text-warning" data-title="Incomplete bonuses"></i>` : `<i data-toggle="tooltip" data-placement="top" class="fa fa-exclamation-circle" data-title="${errorMessage}"></i>`
          },
          {
            name: "SHOW",
            template: `<a data-toggle="tooltip" data-placement="top" data-title="Show" href="/admin/projects/${
                this.projectId
            }/redemptions/${id}" data-title="Show"><i class="fa fa-eye" aria-hidden="true"></i></a>`
          },
          {
            name: "EMAILS",
            template: `<a data-toggle="tooltip" data-placement="top" data-title="Emails history" href="/admin/projects/${
                this.projectId
            }/redemptions/${id}/emails_history" data-title="Email history"><i class="fa fa-envelope" aria-hidden="true"></i></a>`
          }
        ];

        const {presale_status} = params.data;
        const presaleStatusActions = {
          processing: `<i data-toggle="tooltip" data-placement="top" class="fa fa-hourglass-half text-warning" data-title="Presale: Processing"></i>`,
          failed: `<i data-toggle="tooltip" data-placement="top" class="fa fa-exclamation-triangle text-danger" data-title="Presale: Failed"></i>`,
        }

        let res = "";
        if (presale_status && presaleStatusActions[presale_status]) {
          res += `<span class="ico-action">${presaleStatusActions[presale_status]}</span>`;
          // remove Error from display list
          availableActions.shift();
        }

        availableActions.forEach(action => {
          if (params.data.actions.includes(action.name)) {
            res += `<span class="ico-action">${action.template}</span>`;
          } else {
            res += '<span class="ico-action"></span>';
          }
        });

        return res;
      } else {
        return '';
      }
    },

    promotionCellRenderer(params) {
      const id = params.data && params.data.promotion_id;

      if (id) {
        let res = `<a data-toggle="tooltip" data-placement="top" target="_blank" href="/admin/projects/${
            this.projectId
        }/promotions/${id}" data-title="Go to promotion settings"><i class="fa fa-external-link" aria-hidden="true"></i></a>`;

        return `<span class="cell-filter-clickable">${
            params.data.promotion_slug
        }</span> ${res}`;
      } else {
        return this.loader;
      }
    },

    dataCellRenderer(params) {
      const id = params.data && params.data.id;
      let res = this.loader;

      try {
        if ((params.value !== undefined) && id) {
          if (params.value?.includes(';')) {
            res = `<span class="text-nowrap">Multiple Serials ${document.getElementById(`multiple-serials__${params.data.id}`)?.innerHTML}</span>`;
          } else {
            res = `<span class="cell-filter-clickable text-overflow">${params.value}</span>`;
          }
        }
      } catch (e) {
      }

      return res;
    },

    amountCellRenderer(params) {
      const id = params.data && params.data.id;
      let res = this.loader;

      try {
        if ((params.value !== undefined) && id) {
          if (params.value?.includes(';')) {
            const amounts = params.value.split(';').map(value => Number(value));
            const sum = amounts.reduce((partialSum, a) => partialSum + a, 0);
            res = `<span class="text-nowrap">${sum.toFixed(2)} ${document.getElementById(`multiple-denoms__${params.data.id}`)?.innerHTML}</span>`;
          } else {
            res = `${params.value}`;
          }
        }
      } catch (e) {
      }

      return res;
    },


    timeCellRenderer(params) {
      const id = params.data && params.data.promotion_id;
      if (id) {
        const normalized = params && params.value.split(", ");
        return `${normalized[0]}<br/>${normalized[1]}, ${normalized[2]}`;
      } else {
        return "";
      }
    },

    onError(error) {
      this.hasError = true;
      this.message =
          (error.data && error.data.message) ||
          "Something went wrong. Please try again";

      this.hideNotification();
    },

    onSuccess(message) {
      this.message = message || "Success.";
      this.hideNotification();
    },

    hideNotification(timeout) {
      setTimeout(() => {
        this.message = null;
        this.hasError = false;
      }, timeout || 5000);
    },
  },

  computed: {
    requestUrl() {
      return `${window.location.origin}${this.url}`;
    },
    gridState() {
      return location.hash ? qs.parse(location.hash.substring(1)) : {}
    },
    columns() {
      let list = [];
      const optionsList = {
        email: {
          headerName: this.columnsListNames["email"],
          field: "email",
          cellRenderer: this.emailCellRenderer,
          minWidth: 100,
          data: {
            filter: "email"
          },
          ...filterByDefault({
            filterOptions: ["contains"],
            suppressAndOrCondition: true
          }),
        },
        serial_number: {
          headerName: this.columnsListNames["serial_number"],
          cellClass: ['ag-cell-overflow_by-serial'],
          autoHeight: false,
          wrapText: false,
          width: 250,
          minWidth: 150,
          field: "serial_number",
          cellRenderer: this.dataCellRenderer,
          ...filterByDefault({
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
          }),
        },
        promotion_slug: {
          headerName: this.columnsListNames["promotion_slug"],
          field: "promotion_slug",
          filter: "agTextColumnFilter",
          cellRenderer: this.promotionCellRenderer,
          ...filterByEqualSelect({
            filterOptions: ['contains'],
            suppressAndOrCondition: true,
          })
        },
        card_amount: {
          headerName: this.columnsListNames["card_amount"],
          cellClass: ['ag-cell-overflow_by-serial'],
          autoHeight: false,
          wrapText: false,
          field: "card_amount",
          cellRenderer: this.amountCellRenderer,
          width: 130,
          minWidth: 110,
          maxWidth: 130
        },
        bonus_amount: {
          headerName: this.columnsListNames["bonus_amount"],
          field: "bonus_amount",
          width: 110,
          minWidth: 110,
          maxWidth: 110
        },

        time: {
          headerName: this.columnsListNames["time"],
          field: "time",
          cellClass: ["ag-cell-time", "ag-cell-auto"],
          autoHeight: true,
          wrapText: true,
          sortable: true,
          width: 160,
          minWidth: 160,
          cellRenderer: this.timeCellRenderer,
          ...filterByDefault({
            filterOptions: ["equals", "greaterThan", "lessThan", "inRange"],
            suppressAndOrCondition: true,
          }),
          filter: "agDateColumnFilter",
        },

        actions: {
          headerName: this.columnsListNames["actions"],
          field: "actions",
          cellClass: ["ag-cell-actions", "ag-cell-no-overflow"],
          width: 107,
          minWidth: 107,
          maxWidth: 107,
          cellRenderer: this.actionCellRenderer,
          pinned: "right",
        },

        external_platform: {
          headerName: this.columnsListNames["external_platform"],
          field: "external_platform",
          cellClass: ['ag-cell-center', 'ag-cell-auto'],
          autoHeight: true,
          wrapText: true
        }
      };

      this.columnsList.forEach(item => {
        if (this.visibleColumns.indexOf(item) !== -1) {
          list.push(optionsList[item] || []);
        }
      });
      return list;
    }
  }
};
</script>
