<template>
  <div v-if="data && data.redemption_id">
    <template v-if="list.length">
      <h4>Email history</h4>
      <table class="table table-sm table-hover bg-white">
        <thead>
          <tr>
            <th>Email Type</th>
            <th>Sent To (Customer)</th>
            <th>Sent By (User)</th>
            <th>Date/Time</th>
            <th  v-if="extraFields.length">Extra Fields</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in list" :key="item.updated_at">
            <td>
              <template v-if="index">Resend</template>
              <template v-else>Redemption</template>
            </td>
            <td>
              {{item.to}}
            </td>
            <td>
              {{item.by}}
            </td>
            <td>
              <span class="text-nowrap">
                {{date(item.sent_at, 'YYYY-MM-DD')}}
              </span>
              <span class="text-nowrap">
                {{date(item.sent_at, 'HH:mm:ss')}} UTC
              </span>
            </td>
            <td v-if="extraFields.length">
              <div v-for="f in extraFields" class="mr-2">
                <span class="text-nowrap">{{f.label}}:</span> <strong v-text="f.value"/>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-else>
      <span v-if="data.created_at" class="mr-2">
        Redeemed at: <strong>{{date(data.created_at, 'YYYY-MM-DD HH:mm:ss')}} UTC</strong>
      </span>
      <div>
        <span v-if="data.email" class="mr-2">
          Customer Email: <strong>{{data.email}}</strong>
        </span>

        <template v-if="extraFields.length">
          <span v-for="f in extraFields" class="mr-2">
            <span class="text-nowrap">{{f.label}}:</span> <strong v-text="f.value"/>
          </span>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      required: true,
      type: Array, // of email_history entity
      default: () => ([]),
    },
    data: {
      required: true,
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    extraFields() {
      const res = [];
      ['merchant', 'costco_member_number'].forEach((key) => {
        if (this.data[key]) {
          res.push({
            label: key.replace(/\_|\-/gi, ' ').capitalize(),
            value: this.data[key],
          });
        }
      });

      return res;
    },
  },

  methods: {
    date(dateTime, format) {
      return this.$moment(dateTime).utc().format(format);
    },

    currency(value) {
      // todo: get formatting from promo settings
      return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    },
  }
};
</script>

<style scoped lang="scss">
  table.table {
    thead th {
      vertical-align: inherit !important;
      padding: 0.5rem !important;
      text-align: left;
    }

    tbody tr td {
      padding: 0.5rem !important;
      text-align: left;
    }
  }
</style>
