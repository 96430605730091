<template>
  <div class="modal show" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{title}}</h5>
        </div>
        <modal-body :modalContentName="modalContentName" :modalData="modalData" :changesAccepted="changesAccepted" @allowChanges="allowChanges"></modal-body>
        <modal-footer @hideModal="hideModal" @acceptChanges="acceptChanges" :changesAllowed="changesAllowed"></modal-footer>
      </div>
    </div>
  </div>
</template>

<script>
import modalBody from './modalBody'
import modalFooter from './modalFooter'

export default {
  name: 'modal',
  components: {
    'modal-body': modalBody,
    'modal-footer': modalFooter
  },
  props: ['title', 'modalContentName', 'modalData'],
  data: function(){ return {
    changesAccepted: false,
		changesAllowed: false
  }},
  methods: {
    hideModal: function(){ this.$emit('hideModal', false)},
    acceptChanges: function(){ this.changesAccepted = true },
		allowChanges: function(changesAllowed){ this.changesAllowed = changesAllowed }
  }
}
</script>

<style scoped>
  .modal.show{
    background-color:rgba(0, 0, 0, 0.252)
  }

  .modal-dialog{
    max-width: 1000px;
  }
</style>
